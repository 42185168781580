.content {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #222222;
    display: grid;
    max-width: 500px;
    width: 100%;
}

.content .background_userInfo {
    width: 100%;
    height: auto;
}

.content .shadow {
    position: absolute;
    width: 100%;
    bottom: -10px;
    height: 60px;
    background: linear-gradient(to right, black 30%, grey 40%, 30%, rgb(68, 67, 67));
}

.content .background_userInfo,
.content .user_name {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
}

.content .user_name {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    align-self: end;
    height: 30%;
    padding: 20px;
    color: #fff;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8477590865447742) 70%, rgba(0, 0, 0, 0.9485994226792279) 100%);
}

.modal-content {
    background-color: rgb(52, 52, 52);
    position: relative;
    padding: 22px;
    border: 2px solid #222222;
    height: auto;
    border-radius: 0;
    margin-bottom: 15px;
}

.modal-content .close_menu-User_info {
    position: absolute;
    top: 14px;
    right: 7px;
}

.modal-content .userInfoModal img {
    border: 2px solid #222222;
}


.userInfoModal img {
    width: 100%;
    border-radius: 0;
}

.modal-content .userInfoModal p {
    text-align: center;
    color: rgb(228, 227, 227);
    font-size: 20px;
    margin-top: 30px;
    font-weight: normal;
}

.userInfoModal .userPhoto_modal {
    z-index: 99;
    border: none;
    font-size: 34px;
    color: rgb(255, 255, 255);
    background-color: transparent;
}

.close_menu-User_info {
    display: flex;
    justify-content: flex-end;
    margin: 0px 12px 0 0;
}


.carousel {
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    margin-top: 0px;
}

.carousel .left {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 68px;
    color: rgb(141, 141, 141);
}

.carousel .usersData {
    background-color: rgb(201, 198, 198);
    padding: 12px 16px;
    width: 200px;
    padding: 12px 16px;
    border: 4px solid rgb(100, 100, 100);
    height: 60px;
    text-align: center;
    border-radius: 26px;
    margin-top: 18px;
}

.modal-dialog-centered {
    min-height: 100%;
}