.header_content {
    background-color: #222222;
    width: 100%;
    border-bottom: 5px solid rgb(45, 42, 42);
}

.header_content .logo {
    margin: 15px 0;
    width: 150px;
}


/* right_content */
.header_content .right_part__header {
    display: flex;
}

/* House mark */
.header_content .icons {
    font-size: 24px;
    margin: 0 10px;
}

/* User mark */
.right_part__header .user  {
    color: white;
    margin-top: 8px;
}


/* question mark */
.icon_item {
    width: 35px;
    height: 35px;
    background-color: rgb(25, 169, 226);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: serif;
    margin: 0 12px;
}

.icon_item button {
    border: none;
    background-color: transparent;
    outline: none;
}

.question_mark {
    color: rgb(255, 255, 255);
    font-size: 24px;
}

.header_modal .userPhoto_modal {
    z-index: 99;
    border: none;
    font-size: 34px;
    color: rgb(255, 255, 255);
    background-color: transparent;
}

.close_menu-User_info {
    display: flex;
    justify-content: flex-end;
    margin: 0px 12px 0 0;
}


.modal-text {
    color: white;
    padding: 15px 16px;
    margin: 10px 0;
    width: 100%;
}
